
import rootApi from "./rootApi";

import utils from "../utils";
const path = require("../config").path;
const getPrice = require('../utils/productData').getPrice;
const getOrderDetail=async function(orderId){
    try{
        let resData=await rootApi.get(path.customer.shop_order_detail_3rdParty+"/"+orderId)
        let data = resData.data.ticketArray[0];
        if(utils.isEmpty(data.termDateArray)) {
            let termArrayWithDate = [];
            data.termArray.forEach(dt => {
                termArrayWithDate.push({
                    termValue: dt,
                    date: dt,
                });
            });
            data.termArrayWithDate = termArrayWithDate;
        } else {
            data.termArrayWithDate = data.termDateArray;
            if(data.productId !== 6) {
                for(let i=0; i<data.termArrayWithDate.length; i++) {
                    data.termArrayWithDate[i].date = data.termArrayWithDate[i].date.formatDateTime("-dmy", "/dmy")
                }
            }
        }

        data.totalPrice = getPrice(data.value, data.termArray, data.productId, data.productOptionId);
        return [null,{...resData.data,ticketArray:[data]}]
    }catch(err){
        console.log(err);
        return [err];
    }
}
export{
    getOrderDetail
}