import React, { Component } from "react";

import { Input, FormGroup, Row, Col, CustomInput } from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import { Smartphone, Bell, Instagram, User } from "react-feather";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import { connect } from "react-redux";
import { userChange } from "../../redux/actions/userAction";
import {isAndroid,isIOS ,isDesktop} from 'react-device-detect';
import apiLogin from "../../api/Auth";

import ValidInput from "../../utils/ValidInput";
import utils from "../../utils";

import anime from "animejs";
import smalltalk from "smalltalk";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      isOpen: true,
      agree: false,
    };
    this.salerId=null
  }

  componentDidMount() {
    const _this = this;
    this.closeDownload = anime({
      targets: ".app-download",
      scale: 0,
      duration: 500,
      easing: "linear",
      loop: false,
      autoplay: false,
      complete: function () {
        _this.toggle("isOpen");
      },
    });

    let params = window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    if (params.salerId === undefined) {
      window.location.replace("/");
      return;
    }
    this.salerId=params.salerId
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRegister() { 
    if (!this.state.agree) {
      utils.alert("Vui lòng đồng ý với điều khoản sử dụng của chúng tôi!");
      return;
    }

    if (this.state.isLoading) return;

    if (ValidInput.testPassword(this.state.password)) {
      return;
    }

    if (ValidInput.testPhoneNumber(this.state.phoneNumber)) {
      return;
    }
    if (!this.salerId || this.salerId == '') {
      utils.alert("refer wrong");
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      utils.alert("Xác nhận mật khẩu không chính xác!");
      return;
    }

    this.setState({ isLoading: true });
  
    apiLogin.register(
      {
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        salerId:this.salerId
      },
      (err, result) => {
        if (err) {
          utils.alert(err);
          this.setState({ isLoading: false });
        } else {
          utils.alert("Đăng ký thành công! Đang chuyển hướng tới trang tải ứng dụng....");
          setTimeout(() => {
            this.navigateStore(result)
          }, 2000);
        
        }
      }
    );
  }
  openTab (href, target = '_blank') {
    const link = Object.assign(document.createElement('a'), { href });
    document.body.append(link);
    link.click();
    link.remove();
    console.log(`Opening: ${href}`);
  }
  navigateStore(result){
   // return;
    let user = utils.filterUserInfo(result);
    if(isDesktop){
      this.props.userChange(user);
      this.props.history.push("/");
    }
    else if(isIOS){
      this.openTab("https://apps.apple.com/vn/app/v%C3%A9-s%E1%BB%91-tv/id6737541155?l=vi");
      //return;
    }
    else if(isAndroid){
      this.openTab( "https://play.google.com/store/apps/details?id=com.tranlong.vesotv");
      //return;
    }
  }

  toggle(component) {
    this.setState({
      [component]: !this.state[component],
    });
  }

  render() {
    return (
      <div className="d-flex flex-column h-100 bootstrap-reset overflow-hidden">
        <div className="h-100 overflow-auto">
          <Col className="px-4 py-2 h-100">
            <Row className="justify-content-center mb-2">
              {/*<img className={"logo"} src={"/img/logo/logo.png"} alt={"logo"} style={{width: "100px", height: "100px"}}/>*/}
            
            </Row>
         
            <FormGroup>
              {/*<Label>Số điện thoại</Label>*/}
              <Input
                type="number"
                pattern="[0-9]*"
                name="phoneNumber"
                placeholder="Số điện thoại"
                onChange={this.handleChange.bind(this)}
              />
             
            </FormGroup>
            <FormGroup>
              {/*<Label>Mật khẩu</Label>*/}
              <Input
                type="password"
                name="password"
                placeholder="Mật khẩu"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              {/*<Label>Xác nhận mật khẩu</Label>*/}
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Xác nhận mật khẩu"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <div className="d-flex">
              <div>
                <CustomInput
                  id={"agreeTerm"}
                  type={"checkbox"}
                  checked={this.state.agree}
                  onChange={this.toggle.bind(this, "agree")}
                />
              </div>
              <div style={{ fontSize: "0.875rem" }}>
                Tôi đủ 18 tuổi, đã xem và đồng ý với tất cả&nbsp;
                <a href="/term" target="_blank">
                  điều khoản
                </a>
                &nbsp;sử dụng
              </div>
            </div>
            <Row className="justify-content-center mt-2">
              {/* <ButtonLoading
                title={"Đăng ký"}
                loading={this.state.isLoading}
                onClick={this.handleRegister.bind(this)}
              /> */}
              <div className="d-flex feature-page-item-2 align-items-center">
                <div >
                  <User color={"white"} fill="white" />
                  <ButtonLoading
                    title={"Đăng ký"}
                    loading={this.state.isLoading}
                    onClick={this.handleRegister.bind(this)}
                  />
                </div>
              </div>
            </Row>
            {/* <Row className="mt-2 justify-content-center">
              <Link to="/auth/sign-in">Đã có tài khoản? Đăng nhập ngay?</Link>
            </Row> */}
            <Row className="my-2 w-100 justify-content-center">
              <div className="w-50 border-top"></div>
            </Row>
            <Row
              className="justify-content-center font-weight-bold"
              style={{ textDecoration: "underline" }}
            >
              Các tính năng nổi bật
            </Row>
            <Row className="feature-page-container mt-1 pH ">
              <Col>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Smartphone color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Đặt mua VESOTV mọi lúc mọi nơi</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Bell color={"red"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Tự động thông báo trúng thưởng</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Bell color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Tự động thông báo trúng thưởng</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <User color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Thông tin khách hàng được in sau vé</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Instagram color={"white"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Nhận hình ảnh thực của vé đã mua</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Instagram color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Nhận hình ảnh thực của vé đã mua</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
        {/*<div>*/}
        {/*    <Col>*/}
        {/*        <Row className={`app-download ${this.state.isOpen ? "" : "d-none"}`}>*/}
        {/*            <div className="px-3">*/}
        {/*                <Col>*/}
        {/*                    <Row className="justify-content-center py-1">*/}
        {/*                        Tải ứng dụng VietLuck*/}
        {/*                        <XCircle*/}
        {/*                            className="btn-close hover-pointer"*/}
        {/*                            onClick={() => this.closeDownload.play()}*/}
        {/*                        />*/}
        {/*                    </Row>*/}
        {/*                    <Row className="pb-3 mt-1">*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://play.google.com/store/apps/details?id=com.vietluckvn&hl=en_US"}>*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/google-play.jpg"}*/}
        {/*                                    alt="Google Play"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://tintuc.vietluck.vn/iphone-ios"} target="_blank">*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/appstore.jpg"}*/}
        {/*                                    alt="App store"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                    </Row>*/}
        {/*                </Col>*/}
        {/*            </div>*/}
        {/*        </Row>*/}
        {/*    </Col>*/}
        {/*</div>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { userChange })(SignIn));
