import React, { Component } from 'react';
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showSidebar, toggleSidebar, hideSidebar } from "../redux/actions/sidebarActions";
import { userChange, userClear } from "../redux/actions/userAction";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Gift, LogOut, RefreshCcw, X } from "react-feather";
import { Col, Row } from "reactstrap";
import { deepCompareObj, isEmpty } from "../utils";
import apiMyAccount from "../api/MyAccount";
import anime from "animejs";
import routes from "../routes";
import {
    faHome,
    faStore,
    faMoneyBill,
    faChartLine,
    faUser,
    faPhone,
    faLock,
    faTrophy,
    faWallet
} from "@fortawesome/free-solid-svg-icons";
const SidebarItem = withRouter(
    ({ name, to, location, icon, closeSidebar }) => {
        return (
            <Link to={to} className="link-unset row w-100" onClick={() => { if (to === location.pathname) closeSidebar() }} >
                <Col className="h-100">
                    <Row className={`h-100 sidebar-item align-items-center ${to === location.pathname ? "active" : ""}`}>
                        <FontAwesomeIcon icon={icon} className="mx-2" color="red" />
                        {name}
                    </Row>
                </Col>
            </Link>
        )
    }
);

class Sidebar2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        anime({
            targets: '.btn-close-sidebar',
            scale: [
                { value: 2, duration: 2000, easing: 'easeOutExpo' },
                { value: 1, duration: 2000 },
            ],
            easing: 'easeOutElastic(1, .8)',
            loop: true
        });
        this.animeSyncWallet = anime({
            targets: '.btn-sync-wallet',
            rotate: -360 * 2,
            duration: 2000,
            easing: 'linear',
            loop: false,
            autoplay: false
        });
        const that = this;
        setTimeout(function () {
            that.props.hideSidebar()
        }, 100)
    }

    componentWillMount() {
        // console.log("cc")
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            !deepCompareObj(nextProps, this.props)
        )
    }

    handleSyncWallet() {
        if (this.state.isLoading) return;
        this.setState({ isLoading: true });
        this.animeSyncWallet.play();
        apiMyAccount.getUserInfoSomeKey((err, result) => {
            if (err) {
                this.setState({ isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.props.userChange(result);
            }
        })
    }

    render() {
        const isLogin = !isEmpty(this.props.user.authToken) && this.props.user.isActive;

        return (
            <div className={`sidebar2 ${this.props.sidebar.isOpen ? "show" : "hide"}`}>
                <span
                    className="btn-close-sidebar hover-pointer" onClick={() => {
                        this.props.toggleSidebar()
                    }}
                >
                    <X color="white" />
                </span>
                <div className="bootstrap-reset p-0 m-0 user-information bgSidebar" style={{ backgroundColor: '#fe592c' }}>
                    <Col className="py-2">
                        <Row className="py-1 px-3">
                            <div>
                                <NavLink to={"/profile"} className="link-unset" onClick={() => { if (window.location.pathname === "/") this.props.hideSidebar() }}>
                                    <img src={"/img/logo/avatar_vesoviet.png"} className="avatar-circle-2x" alt="avatar" />
                                </NavLink>
                            </div>
                            <div className="ml-2 justify-content-center d-flex flex-column">
                                {
                                    isLogin ?
                                        <Link to={"/profile"} className="hover-pointer link-unset">
                                            <div>
                                                {this.props.user.fullName}
                                            </div>
                                            <div>
                                                {this.props.user.phoneNumber}
                                            </div>
                                        </Link>
                                        :
                                        <div>
                                            Bạn chưa đăng nhập
                                        </div>
                                }
                            </div>
                        </Row>
                        <Row className="py-1 px-3 financial">
                            <div style={{ color: 'yellow' }}>Tài khoản <RefreshCcw
                                size={20}
                                className="hover-pointer btn-sync-wallet"
                                onClick={this.handleSyncWallet.bind(this)}
                            /></div>
                            <div className="w-75">
                                <div></div>
                                <div style={{ marginTop: 5, marginLeft: -5 }}>
                                    <FontAwesomeIcon icon={faWallet} className="mx-2" />
                                    {(this.props.user.availableBalance * 1000).getMoneyFormat()} ₫
                                </div>
                                <div style={{ marginTop: 5, marginLeft: -5 }}>
                                    <FontAwesomeIcon icon={faTrophy} className="mx-2" color="yellow" />
                                    {(this.props.user.winingBalance * 1000).getMoneyFormat()} ₫
                                </div>
                            </div>
                            <div className="w-25 text-right">
                                <div>
                                </div>
                                <div>
                                    <Link to={"/recharge"} className="link-unset" onClick={() => { if (window.location.pathname === "/recharge") this.props.hideSidebar() }}>
                                        <span>Nạp</span>
                                    </Link>
                                </div>
                                <div className="mt-1">
                                    <Link to={"/withdrawal"} className="link-unset" onClick={() => { if (window.location.pathname === "/withdrawal") this.props.hideSidebar() }}>
                                        <span>Rút</span>
                                    </Link>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </div>

                <div className="bootstrap-reset p-0 m-0 sidebar-content">
                    <Col>
                        {
                            routes.find(route => route.name === "Dashboard").children.map(({ path, name, icon }, index) => {
                                return (
                                    <SidebarItem
                                        key={`sidebar-item-${index}`}
                                        name={name}
                                        icon={icon}
                                        to={path}
                                        closeSidebar={() => this.props.hideSidebar()}
                                    />
                                )
                            })
                        }


                        <a className="link-unset row w-100" href={"/auth/sign-in"}>
                            <Col>
                                <Row className="sidebar-item">
                                    <LogOut color={"#980000"} className="mx-2" />
                                    {
                                        this.props.user.authToken ? "Đăng xuất" : "Đăng nhập / Đăng ký"
                                    }
                                </Row>
                            </Col>
                        </a>

                    </Col>
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(store => ({
        sidebar: store.sidebar,
        layout: store.layout,
        user: store.user
    }), { toggleSidebar, hideSidebar, showSidebar, userClear, userChange })(Sidebar2)
);

