import path, {root,rootIloto} from "./path";


const config = {
    path: path
};

export default config;

export {path, root,rootIloto}
