import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { userChange } from "../redux/actions/userAction";

import { Col, DropdownToggle, Nav, Navbar, Row, UncontrolledDropdown, } from "reactstrap";

import { Bell, Menu, ShoppingCart } from "react-feather";

import { Link } from "react-router-dom";

import apiMyAccount from "../api/MyAccount";

import firebase from "firebase";
import { messaging } from "../push-notification";

import utils from "../utils";

import PushNotify from "./Vietluck/PushNotify";
import {
    faUser
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const NavbarDropdown = ({ count, showBadge, icon: Icon, to }) => (
    <UncontrolledDropdown nav inNavbar className="mr-2">
        <DropdownToggle nav className="nav-icon dropdown-toggle" tag="span">
            <Link to={to}>
                <div className="position-relative">
                    <Icon className="align-middle" color="white" />
                    {showBadge ? <span className="indicator">{count}</span> : null}
                </div>
            </Link>
        </DropdownToggle>
    </UncontrolledDropdown>
);

class NavbarComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unread: 0
        }
    }

    componentDidMount() {
        this.isLogin = !utils.isEmpty(this.props.user.authToken) && this.props.user.isActive;
        if (firebase.messaging.isSupported()) {
            if (this.isLogin) {
                apiMyAccount.getNumNotifyUnread((err, result) => {
                    if (err) {

                    } else {
                        this.setState({ unread: result })
                    }
                });

                this.notification = messaging.onMessage((payload) => {
                    PushNotify("success", payload.notification.title, payload.notification.body);
                    apiMyAccount.getNumNotifyUnread((err, result) => {
                        if (err) {

                        } else {
                            this.setState({ unread: result })
                        }
                    });

                    apiMyAccount.getUserInfoSomeKey((err, result) => {
                        if (err) {

                        } else {
                            this.props.userChange(result);
                        }
                    })
                });
            }
        }
    }

    componentWillUnmount() {
        if (this.notification) {
            this.notification()
        }
    }

    handleReadAll() {
        // apiMyAccount.setReadAll()
    }


    render() {
        const { authToken, isActive } = this.props.user;
        const isLogin = authToken && isActive;
        const no_cart = window.location.hash === "#keno" || window.location.pathname === "/lottery/keno" || !isLogin;
        const cartData = this.props.cartData;
        return (
            <Navbar light expand fixed={"true"} className="bootstrap-reset bgNav">
                <Col className="w-100">
                    <Row className="align-items-center">
                        <Col xs={4}>
                            <span
                                className="sidebar-toggle d-flex mr-2"
                                onClick={() => {
                                    this.props.toggleSidebar();
                                }}
                            >
                                <Menu color="white" />
                            </span>
                        </Col>
                        <Col xs={4} className="text-center">
                            <a href={"/"} style={{ color: '#fff', fontWeight: 'bold' }} className="link-unset">VESOTV
                                {/* <img
                                    src={"/img/logo/logo-white.png"}
                                    style={{width: "90%"}}
                                    alt="VESOTV"
                                    title="VESOTV"
                                /> */}
                            </a>
                        </Col>
                        <Col xs={4} className="text-right">
                            <Nav className="justify-content-end" navbar>
                                {
                                    isLogin &&
                                    <span>
                                        <NavbarDropdown
                                            icon={Bell}
                                            count={this.state.unread}
                                            showBadge
                                            to="/notifications"
                                        />
                                    </span>
                                }
                                {
                                    !no_cart &&
                                    <NavbarDropdown
                                        icon={ShoppingCart}
                                        count={cartData.filter(dt => dt.productId !== 6).length}
                                        showBadge
                                        to="/cart"
                                    />
                                }{
                                    !isLogin &&
                                    <div className="feature-page-item-3 align-items-center">
                                        <a href="/auth/sign-in" style={{ cursor: 'pointer', borderRadius: 10, display: "flex", padding: 4, color: '#ffffff', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <FontAwesomeIcon
                                                icon={faUser}
                                                style={{ marginRight: 2 }}
                                                color="white"
                                            />
                                            Đăng nhập
                                        </a>
                                    </div>
                                }
                            </Nav>
                        </Col>
                    </Row>
                </Col>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        user: state.user,
        cartData: state.cartData.cart
    }
}

export default connect(mapStateToProps, { userChange, toggleSidebar })(NavbarComponent);
