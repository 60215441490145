import React, { Component } from "react";

import { Input, FormGroup, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import { connect } from "react-redux";
import { userChange, userClear } from "../../redux/actions/userAction";

import apiLogin from "../../api/Auth";
import utils from "../../utils";
import { Bell, Instagram, Smartphone, User } from "react-feather";
import anime from "animejs";
import smalltalk from "smalltalk";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      isLoading: false,
      isOpen: true,
    };
  }

  componentDidMount() {
    this.props.userClear();
    const _this = this;
    this.closeDownload = anime({
      targets: ".app-download",
      scale: 0,
      duration: 500,
      easing: "linear",
      loop: false,
      autoplay: false,
      complete: function () {
        _this.toggle("isOpen");
      },
    });

    // anime({
    //     targets: '.logo',
    //     rotate: 360,
    //     duration: 3600,
    //
    //     easing: "linear",
    //     loop: false,
    //     autoplay: true,
    // })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleLogin() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    apiLogin.login(
      {
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
      },
      (err, result) => {
        if (err) {
          this.setState({ isLoading: false });
        } else {
          this.props.userChange(result);
          if (!result.isActive) {
            utils.confirm(
              "Tài khoản chưa được kích hoạt! Kích hoạt ngay?",
              (check) => {
                apiLogin.requestOTP((err, result) => {
                  if (result) {
                    window.location.replace("/auth/otp");
                  } else {
                    this.setState({ isLoading: false });
                  }
                });
              }
            );
          } else {
            window.location.replace("/");
          }
        }
      }
    );
  }

  toggle(component) {
    this.setState({
      [component]: !this.state[component],
    });
  }

  render() {
    return (
      <div className="d-flex flex-column h-100 overflow-hidden">
        <div className="h-100 overflow-auto">
          <Col className="px-4 py-2 h-100">
            <Row className="justify-content-center mb-2">
              {/*<img className={"logo"} src={"/img/logo/logo.png"} alt={"logo"} style={{width: "100px", height: "100px"}}/>*/}
              <Col>
                <Row className="justify-content-center py-1">
                  Tải App mua VESOTV chính thống
                </Row>
                <Row className="pb-3 mt-1">
                  <Col col={6} className="px-3">
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.tranlong.vesotv"
                      }
                      target="_blank"
                    >
                      <img
                        src={"/img/logo/google-play.jpg"}
                        alt="Google Play"
                        className="w-100 hover-pointer"
                      />
                    </a>
                  </Col>
                  <Col col={6} className="px-3">
                    <a
                      href={
                        "https://apps.apple.com/vn/app/v%C3%A9-s%E1%BB%91-tv/id6737541155?l=vi"
                      }
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={"/img/logo/appstore.jpg"}
                        alt="App store"
                        className="w-100 hover-pointer"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <h3>Đăng nhập</h3>
            </Row>
            <FormGroup>
              {/*<Label>Số điện thoại</Label>*/}
              <Input
                type="number"
                pattern="[0-9]*"
                name="phoneNumber"
                placeholder="Số điện thoại"
                onChange={this.handleChange.bind(this)}
                onFocus={() => {
                  if (this.state.isOpen) this.closeDownload.play();
                }}
              />
            </FormGroup>
            <FormGroup>
              {/*<Label>Mật khẩu</Label>*/}
              <Input
                type="password"
                name="password"
                placeholder="Mật khẩu"
                onChange={this.handleChange.bind(this)}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    this.handleLogin();
                  }
                }}
                onFocus={() => {
                  if (this.state.isOpen) this.closeDownload.play();
                }}
              />
            </FormGroup>

            <Row className="justify-content-center mt-3">
              <div className="d-flex feature-page-item-2 align-items-center">
                <div >
                  <User color={"white"} fill="white" />
                  <ButtonLoading
                    title={" Đăng nhập"}
                    loading={this.state.isLoading}
                    onClick={this.handleLogin.bind(this)}
                  />
                </div>
              </div>
              {/* <ButtonLoading
                title={" Đăng nhập"}
                loading={this.state.isLoading}
                onClick={this.handleLogin.bind(this)}
              /> */}
            </Row>
            <Row className="mt-3 justify-content-center">
              <Link to="/auth/sign-up">Chưa có tài khoản? Đăng ký ngay?</Link>
            </Row>
            <Row className="justify-content-center my-2">
              <Link to={"/auth/forgot-password"}>Quên mật khẩu?</Link>
            </Row>
            <Row className="m-0 my-4 w-100 justify-content-center">
              <div className="w-50 border-top"></div>
            </Row>
            <Row
              className="justify-content-center font-weight-bold"
              style={{ textDecoration: "underline" }}
            >
              Các tính năng nổi bật
            </Row>
            <Row className="feature-page-container pH mt-1 justify-content-center">
              <Col >
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Smartphone color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Đặt mua VESOTV mọi lúc mọi nơi</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Bell color={"red"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Tự động thông báo trúng thưởng</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Bell color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Tự động thông báo trúng thưởng</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <User color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Thông tin khách hàng được in sau vé</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Instagram color={"white"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Nhận hình ảnh thực của vé đã mua</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Instagram color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Nhận hình ảnh thực của vé đã mua</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
        {/*<div>*/}
        {/*    <Col>*/}
        {/*        <Row className={`app-download ${this.state.isOpen ? "" : "d-none"}`}>*/}
        {/*            <div className="px-3">*/}
        {/*                <Col>*/}
        {/*                    <Row className="justify-content-center py-1">*/}
        {/*                        Tải ứng dụng VietLuck*/}
        {/*                        <XCircle*/}
        {/*                            className="btn-close hover-pointer"*/}
        {/*                            onClick={() => this.closeDownload.play()}*/}
        {/*                        />*/}
        {/*                    </Row>*/}
        {/*                    <Row className="pb-3 mt-1">*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://play.google.com/store/apps/details?id=com.vietluckvn&hl=en_US"}>*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/google-play.jpg"}*/}
        {/*                                    alt="Google Play"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://tintuc.vietluck.vn/iphone-ios"} target="_blank">*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/appstore.jpg"}*/}
        {/*                                    alt="App store"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                    </Row>*/}
        {/*                </Col>*/}
        {/*            </div>*/}
        {/*        </Row>*/}
        {/*    </Col>*/}
        {/*</div>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { userChange, userClear })(SignIn);
