import rootApi, { getToken } from "./rootApi";

import ErrorMessage from "../utils/ErrorMessage";

/* eslint eqeqeq: "off" */

const utils = require('../utils');

const path = require('../config').path;

const moment = require('moment');

const getPrice = require('../utils/productData').getPrice;

function getUserInfo(callback) {
    rootApi.get(path.customer.get_info)
        .then(res => {
            let result = res.data;
            result.authToken = getToken();
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function filterUserInfo(data) {
    try {
        let user = {};
        ['id', 'isActive', 'fullName', 'gender', 'phoneNumber', 'address', 'idCardNumber', 'walletId', 'orderShoppingCartId', 'cityId', 'districtId', 'email', 'balance', 'availableBalance', 'authToken'].forEach(key => {
            user[key] = data[key];
        });
        user.balance = data.wallet.balance;
        user.winingBalance=data.wallet.winingBalance
        user.availableBalance = data.wallet.availableBalance;
        return user;
    } catch (e) {
        return null;
    }

}

function getUserInfoSomeKey(callback) {
    getUserInfo((err, result) => {
        if (err) {
            return callback(err, null);
        } else {
            let user = filterUserInfo(result);
            if (user === null) {
                return callback('Wrong when filter user info');
            } else {
                return callback(null, user);
            }
        }
    });
}

function editUserInfo(data, callback) {
    rootApi.patch(path.customer.edit_info + '/' + data.id, data)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function changePassword(data, callback) {
    rootApi.post(path.customer.change_password, {
        currentPassword: data.current_password,
        password: data.password,
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function requestTransfer(data, callback) {
    let dt = {
        transferType: data.type,
        amount: data.amount / 1000,
        bankName: data.bankCode,
        bankIdNumber: data.bankAccount,
        ownerName: data.ownerName,
        proof: '',
        note: '',
    };
    Object.keys(dt).forEach(key => {
        if (dt[key] === null || dt[key] === undefined) {
            delete dt[key];
        }
    });
    rootApi.post(path.customer.request_transfer, dt)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function getWalletInfo(callback) {
    rootApi.get(path.customer.wallet.info)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function getTransactionById(data, callback) {
    let key = '';
    let params = '';
    if (data.id) {
        params = data.id;
        key = 'id';
    }
    if (data.requestId) {
        params = data.requestId;
        key = 'requestId';
    }

    rootApi.get(path.customer.wallet.transaction + `?${key}=${params}`)
        .then(res => {

            if (utils.isEmpty(res)) {
                return callback(null, null);
            }
            let dt = res.data;
            // dt.source = dt.source.sourceType;
            return callback(null, dt);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function getTransactionInfo(page, status, callback) {
    /*
    this.fluctuationTypeId = {
            "1": "Mua vé",
            "2": "Rút tiền",
            "3": "VNPAY",
            "4": "Nạp chuyển khoản",
            "5": "Nạp tại cửa hàng",
            "6": "Trúng thưởng",
            "8": "Yêu cầu nạp tiền"
        };
        this.status = [
            "Đang xử lý", "Thành công", "Đã hủy"
        ];
     */
    let url = page ? path.customer.wallet.transaction + '?page=' + page : path.customer.wallet.transaction;
    if (status === 0 || status === 1 || status === 2) {
        if (page) {
            url = url + `&status=${status}`;
        } else {
            url = url + `?status=${status}`;
        }
    }

    rootApi.get(url)
        .then(res => {

            if (utils.isEmpty(res.data)) {
                return callback(null, null);
            }
            let result = [];
            res.data.forEach(dt => {
                // if (dt.fluctuationTypeId != 1) {
                // dt.source = dt.source.sourceType;
                dt.groupDate = moment(dt.createdDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY');
                dt.createdDate = moment(dt.createdDate, 'DD-MM-YYYY HH:mm:ss').valueOf();
                result.push(dt);
                // }
            });

            result = result.sortBy('createdDate');
            for (let i = 0; i < result.length; i++) {
                result[i].createdDate = moment(result[i].createdDate).format('DD-MM-YYYY HH:mm:ss');
            }
            result.reverse();
            let data = [];
            let groupDate = groupBy(result, dt => dt.groupDate);
            groupDate.forEach(gr => {
                data.push({
                    groupDate: gr[0].groupDate,
                    data: gr,
                });
            });
            return callback(null, data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });


}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

// function covertValueStringToInt(val) {
//     for (let i = 0; i < val.length; i++) {
//         val[i] = parseInt(val[i]);
//     }
//     return val;
// }

// function convertValue(lottery_type, productOptionId, value) {
//     if (productOptionId == 82) {
//         return [{ '1': 'Lẻ', '2': 'Chẵn', '3': 'Xỉu', '4': 'Tài' }[value[0]]];
//     } else {
//         if (lottery_type == 'max3d' || lottery_type == 'max4d') {
//             return value;
//         } else {
//             return covertValueStringToInt(value);
//         }
//     }
// }

function getShopHistory(page, statusId, orderShoppingCartId, callback) {
    function getTotalPrice(value) {
        try {
            let totalPrice = 0;
            value.forEach(vl => {
                totalPrice += vl.price;
            });
            return totalPrice;
        } catch (e) {
            return 0;
        }
    }

    function getWinningPrice(value) {
        try {
            let winningPrice = 0;
            value.forEach(dt => {
                if (dt.winningNumber === undefined) {
                    winningPrice = -1;
                    return -1;
                } else {
                    Object.keys(dt.winningNumber).forEach(key => {
                        winningPrice += dt.winningNumber[key].profit;
                    });
                }
            });
            return winningPrice;
        } catch (e) {
            return 0;
        }
    }

    let params = '';

    if (statusId) {
        if (statusId === 1 || statusId === 2) {
            params += `&ticketStatusId[]=1&ticketStatusId[]=2`;
        } else {
            params += `&ticketStatusId=${statusId}`;
        }
    }
    if (page) {
        params += `&page=${page}`
    }

    rootApi.get(path.customer.shop_history + '/' + orderShoppingCartId + '?shoppingCart=true' + params)
        .then(res => {
            let data = [];
            let result = [];
            res.data.ticketArray.forEach(dt => {
                data.push({
                    id: dt.id,
                    createdDate: dt.createdDate,
                    groupDate: dt.createdDate.formatDateTime('-dmy', '/dmy'),
                    time: dt.createdDate.split(' ')[1],
                    productId: dt.productId,
                    productOptionId: dt.productOptionId,
                    winningPrice: getWinningPrice(dt.value),
                    totalPrice: getTotalPrice(dt.value),
                });
            });

            let groupData = groupBy(data, dt => dt.groupDate);
            groupData.forEach(dt => {
                result.push({
                    title: dt[0].groupDate,
                    data: dt
                });
            });
            return callback(null, { records: res.data.ticketArray.length, result })
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function getShopHistoryById(id, callback) {

    rootApi.get(path.customer.shop_history + '/' + id)
        .then(res => {
            if (utils.isEmpty(res.data)) {
                return callback(null, null);
            } else {
                let data = res.data;

                data.ticketArray.forEach(dt => {
                    if (utils.isEmpty(dt.termDateArray)) {
                        let termArrayWithDate = [];
                        dt.termArray.forEach(dt => {
                            termArrayWithDate.push({
                                termValue: dt,
                                date: dt,
                            });
                        });
                        dt.termArrayWithDate = termArrayWithDate;
                    } else {
                        dt.termArrayWithDate = dt.termDateArray;
                        if (dt.productId !== 6) {
                            for (let i = 0; i < dt.termArrayWithDate.length; i++) {
                                dt.termArrayWithDate[i].date = dt.termArrayWithDate[i].date.formatDateTime("-dmy", "/dmy")
                            }
                        }
                    }
                    dt.totalPrice = getPrice(dt.value, dt.termArray, dt.productId, dt.productOptionId);
                });
                return callback(null, data);
            }
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function getTicketById(id, callback) {
    rootApi.get(path.customer.ticket + '/' + id)
        .then(
            (res) => {
                if (utils.isEmpty(res.data)) {
                    return callback(null, null);
                } else {
                    let data = res.data;
                    if (utils.isEmpty(data.termDateArray)) {
                        let termArrayWithDate = [];
                        data.termArray.forEach(dt => {
                            termArrayWithDate.push({
                                termValue: dt,
                                date: dt,
                            });
                        });
                        data.termArrayWithDate = termArrayWithDate;
                    } else {

                        data.termArrayWithDate = data.termDateArray;

                        if (data.productId !== 6) {
                            for (let i = 0; i < data.termArrayWithDate.length; i++) {
                                data.termArrayWithDate[i].date = data.termArrayWithDate[i].date.formatDateTime("-dmy", "/dmy")
                            }
                        }
                    }
                    data.totalPrice = getPrice(data.value, data.termArray, data.productId, data.productOptionId);
                    return callback(null, data);
                }
            }
        )
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}

function VNPAY_Payment(data, callback) {
    rootApi.post(path.customer.vnpay.request, {
        amount: data.vnp_Amount / 1000,
        bankCode: data.vnp_BankCode,
        orderType: 'topup',
        language: 'vn',
        linkReturn: path.customer.vnpay.check,
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function VNPAY_Check(data, callback) {
    rootApi.get(path.customer.vnpay.return, {
        params: data
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function Momo_Payment(data, callback) {
    rootApi.post(path.customer.momo.request, {
        amount: data.amount / 1000,
        linkReturn: path.customer.momo.check,
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function Momo_check(data, callback) {
    rootApi.get(path.customer.momo.return, {
        params: data
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function uploadProof(data, callback) {
    // var formData = new FormData();
    // let uriParts = data.file.split('.');
    // let fileType = uriParts[uriParts.length - 1];
    // let dt = {
    //     uri: data.file,
    //     name: `photo.jpg`,
    //     type: `image/jpg`,
    // };
    // formData.append('attached_file', dt);
    // formData.append('contentType', 'wallet');
    // AsyncStorage.getItem('state')
    //     .then((value) => {
    //         let token = JSON.parse(value).user.authToken;
    //         axios.post(path.customer.attachment, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 'Authorization': `Bearer ${token}`,
    //             },
    //         })
    //             .then(res => {
    //                 let pathUrl = res.data.pathUrl;
    //                 axios.post(path.customer.request, {
    //                         amount: data.amount,
    //                         proof: pathUrl,
    //                         note: data.note,
    //                     },
    //                     {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             'Authorization': `Bearer ${token}`,
    //                         },
    //                     })
    //                     .then(res => {
    //                         return callback(null, res.data);
    //                     })
    //                     .catch(error => {
    //                         return callback(error);
    //                     });
    //             })
    //             .catch(error => {
    //                 return callback(error);
    //             });
    //     });
}

/*
*"6"-Thông báo chung
*"1"-Thông báo về nạp tiền
*"2"-Thông báo về ORDER
*"3"-Thông báo về Kết quả mới
*"4"-Thông báo về thắng giải
*"5"- Thông báo về Ticket (hiện tại đăng chỉ gồm đơn hàng bị hủy)
* */
function getNotify(page, callback) {
    let params = {
        page: page ? page : 1,
    };
    if (page === 'unread') {
        params = {
            page: 1,
            unread: 'true',
        };
    }
    rootApi.get(path.customer.notify, { params: params })
        .then(res => {
            if (res.data.length === 0) {
                return callback(null, null);
            } else {
                let data = res.data;
                let results = [];
                data.forEach(dt => {
                    dt.groupDate = dt.createdDate.split(' ')[0];
                    dt.notifyType = parseInt(dt.notifyType);
                    dt.time = dt.createdDate.split(' ')[1];
                    if (!utils.isEmpty(dt.data.type)) {
                        dt.data.type = parseInt(dt.data.type);
                    }
                    results.push(dt);
                });
                let groupDt = groupBy(results, dt => dt.groupDate);
                results = [];
                groupDt.forEach(dt => {
                    results.push({
                        groupDate: dt[0].groupDate,
                        data: dt,
                    });
                });
                return callback(null, results);
            }
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}

function getNumNotifyUnread(callback) {
    let params = {
        unread: true,
        page: 1,
    };
    rootApi.get(path.customer.notify, { params: params })
        .then(res => {
            return callback(null, res.data.length);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error, null);
        });

}

function setReadAll() {
    rootApi.post(path.customer.notify_setAsReadAll, {});

}

function setFcmToken(callback) {
    let fcmToken = localStorage.getItem("fcmToken");
    if (utils.isEmpty(fcmToken)) {
        fcmToken = "ahihi"
    }
    rootApi.patch(path.auth.firebase, {
        tokenFirebase: fcmToken
    })
        .then(res => {
            if (callback)
                return callback(null, "OK");
        })
        .catch(err => {
            if (callback)
                return callback(err, null);
        })
}
function requestTransferWining(data, callback) {
    let dt = {
        transferType: data.type,
        amount: data.amount / 1000,
        bankName: data.bankCode,
        bankIdNumber: data.bankAccount,
        ownerName: data.ownerName,
        proof: '',
        note: '',
    };
    Object.keys(dt).forEach(key => {
        if (dt[key] === null || dt[key] === undefined) {
            delete dt[key];
        }
    });
    rootApi.post(path.customer.request_transferWining, dt)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
  
  }
  function requestWithdrawToWallet(data,callback){
    let dt={amount:data.amount/1000}                         
            rootApi.post(path.customer.withdraw_to_wallet, dt)
                .then(res => {
                    return callback(null, res.data);
                })
                .catch(error => {
                    return callback(error);
                });
       
  }
  function megaPaymentOrder(data, callback){
    rootApi
    .post(path.customer.megapay.createOrder, data)
    .then((res) => {
      return callback(null, res.data);
    })
    .catch((error) => {
      ErrorMessage(error);
      return callback(error);
    });
  
  }
  function megaPayCheckOrder(url,callback){
    rootApi.get(path.customer.megapay.returnOrder,{params:url}).then((res=>{
      return callback(null,res.data)
    })).catch((error) => {
      ErrorMessage(error);
      return callback(error);
    });
  }
  function megaPaymentRecharge(data, callback){
    rootApi
    .post(path.customer.megapay.createRecharge, data)
    .then((res) => {
      return callback(null, res.data);
    })
    .catch((error) => {
      ErrorMessage(error);
      return callback(error);
    });
  
  }

  function getShopHistoryKT(page, statusId, callback) {
    function getTotalPrice(value) {
        try {
            let totalPrice = 0;
            value.forEach(vl => {
                totalPrice += vl.price;
            });
            return totalPrice;
        } catch (e) {
            return 0;
        }
    }

    function getWinningPrice(value) {
        try {
            let winningPrice = 0;
            value.forEach(dt => {
                if (dt.winningNumber === undefined) {
                    winningPrice = -1;
                    return -1;
                } else {
                    Object.keys(dt.winningNumber).forEach(key => {
                        winningPrice += dt.winningNumber[key].profit;
                    });
                }
            });
            return winningPrice;
        } catch (e) {
            return 0;
        }
    }

    let params = '';

    if (statusId) {
        params += `?ticketStatusId=${statusId}`;
    }
    params = page ? params + `&page=${page}` : params;

    rootApi.get(path.customer.shop_history_KT +  params)
        .then(res => {
            let data = [];
            let result = [];
            res.data.ticketArray.forEach(dt => {
                data.push({
                    id: dt.id,
                    createdDate: dt.createdDate,
                    groupDate: dt.createdDate.formatDateTime('-dmy', '/dmy'),
                    time: dt.createdDate.split(' ')[1],
                    productId: dt.productId,
                    productOptionId: dt.productOptionId,
                    winningPrice: getWinningPrice(dt.value),
                    totalPrice: getTotalPrice(dt.value),
                    orderId:dt.orderId
                });
            });

            let groupData = groupBy(data, dt => dt.groupDate);
            groupData.forEach(dt => {
                result.push({
                    title: dt[0].groupDate,
                    data: dt
                });
            });
            return callback(null, { records: res.data.ticketArray.length, result })
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}
export default {
    filterUserInfo: filterUserInfo,
    getUserInfo: getUserInfo,
    getUserInfoSomeKey: getUserInfoSomeKey,
    editUserInfo: editUserInfo,
    changePassword: changePassword,
    requestTransfer: requestTransfer,
    getWalletInfo: getWalletInfo,
    getTransactionInfo: getTransactionInfo,
    getShopHistory: getShopHistory,
    getShopHistoryById: getShopHistoryById,
    VNPAY_Payment: VNPAY_Payment,
    VNPAY_Check: VNPAY_Check,
    Momo_Payment: Momo_Payment,
    Momo_check: Momo_check,
    uploadProof: uploadProof,
    getNotify: getNotify,
    setReadAll: setReadAll,
    getNumNotifyUnread: getNumNotifyUnread,
    getTransactionById: getTransactionById,
    getTicketById: getTicketById,
    setFcmToken: setFcmToken,
    requestTransferWining,
    requestWithdrawToWallet,
    megaPaymentOrder,
    megaPayCheckOrder,
    megaPaymentRecharge,
    getShopHistoryKT
};
